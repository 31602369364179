<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <div v-if="can_list">
            <DxDataGrid
                    v-if="can_list"
                    id="material-table"
                    ref="data-grid"
                    width="100%"
                    :show-borders="true"
                    :data-source="dataMaterial"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    column-resizing-mode="widget"
                    :column-auto-width="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :hover-state-enabled="true"
                    :word-wrap-enabled="true"
                    key-expr="id"
                    exporting="exportGrid"
                    @row-inserted="onInserted"
                    @row-updated="onUpdated"
                    @row-removed="onRemoved"
                    @exporting="onExporting"
            >
                <DxColumn data-field="id" caption="ID" :allow-editing="false"/>
                <DxColumn
                        data-field="created_at"
                        data-type="date"
                        :caption="$t('table.date')"
                        format="dd.MM.yyyy"
                        class="bold-detail"
                        :allow-editing="false"
                />
                <DxColumn
                        data-field="submitter_id"
                        :name="this.$t('note.submitter')"
                        :caption="this.$t('note.submitter')"
                        :allow-editing="false"
                >
                    <DxLookup
                            :data-source="dataUsers"
                            :allowClearing="true"
                            value-expr="id"
                            display-expr="username"
                    />
                </DxColumn>
                <DxColumn
                        data-field="note"
                        :name="this.$t('note.note')"
                        :caption="this.$t('note.note')"
                />

                <DxFilterRow :visible="true"/>
                <DxSearchPanel :visible="true"/>
                <DxColumnFixing :enabled="true"/>
                <DxColumnChooser :enabled="true"/>
                <DxScrolling mode="standard"/>
                <DxGrouping :context-menu-enabled="true"/>
                <DxGroupPanel :visible="true"/>
                <DxPaging :page-size="20"/>

                <DxPager
                        :visible="true"
                        :allowed-page-sizes="pageSizes"
                        :show-page-size-selector="showPageSizeSelector"
                        :show-info="showInfo"
                        :show-navigation-buttons="showNavButtons"
                />
                <DxExport
                        :enabled="true"
                        :visible="true"
                        :allow-export-selected-data="false"
                />
                <DxEditing
                        :allow-updating="can_edit"
                        :allow-deleting="can_delete"
                        :allow-adding="can_create"
                        mode="row"
                        :select-text-on-edit-start="true"
                        :use-icons="true"
                />
            </DxDataGrid>
            <b-modal
                    ref="import-modal"
                    centered
                    title="Import"
                    ok-only
                    :ok-title="$t('buttons.ok')"
            >
                <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
                <b-card-text v-if="importResult.length !== 0">
                    {{ importResult }}
                </b-card-text>
                <div class="d-flex justify-content-center">
                    <b-spinner v-if="importResult.length === 0"/>
                </div>
            </b-modal>
        </div>
          <h1 v-else>{{ $t("noPermission") }}</h1>
      </div>

    </div>

  </b-card>
</template>

<script>
import axios from '@axios';
import {BCard, BModal, BCardText, BSpinner} from 'bootstrap-vue';
import {
  DxDataGrid,
  DxLookup,
  DxColumn,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import {readOnly} from "@/auth/utils";

export default {
  components: {
    BCard,
    DxLookup,
    BCardText,
    BModal,
    BSpinner,
    DxEditing,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxExport,
    DxPager
  },
  data() {
    return {
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      dataUnit: [],
      dataUsers: [],
      dataMaterialGroup: [],
      dataMaterial: [],
      languages: [],
      selectedLanguage: {},
      dataLoading: true,
      error: '',
      file: null,
      importResult: '',
      readOnly: false
    };
  },
  async mounted() {
    this.readOnly = readOnly();
    await this.getPageAuths();
    await this.getLanguage();
    await this.getNoteData();
  },
  methods: {
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', {page: 'Report Notes'});
      this.can_edit = this.readOnly && pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly && pageAuths.data.can_delete;
      this.can_create = this.readOnly && pageAuths.data.can_create;
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUsers() {
      this.error = '';
      const response = await axios.get('/list-site-user');

      try {
        this.dataUsers = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getNoteData() {
      await this.getUsers();
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-note');

      try {
        this.dataMaterial = response?.data || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-Notes');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
              new Blob([buffer], {type: 'application/octet-stream'}),
              'Fama-Notes.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.refreshList();
    },
    handleImport() {
      if (!this.file) {
        this.showToast({
          variant: 'danger',
          title: this.$t('modal.error'),
          text: this.$t('modal.please-choose-file-first')
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.importResult = '';
      this.$refs['import-modal'].show();
      this.showToast({
        variant: 'info',
        icon: 'InfoIcon',
        text: this.$t('modal.start-import')
      });
      axios
          .post(`import-material`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.importResult = this.$t('general.importSuccess');
              this.showToast({
                variant: 'success',
                text: this.importResult
              });
              this.refreshList();
            } else {
              this.importResult = this.$t('general.importError');
              this.showToast({
                variant: 'danger',
                text: this.importResult
              });
            }
          })
          .catch((err) => {
            this.importResult = err?.response?.data || err?.message;
            this.showToast({
              variant: 'danger',
              text: this.importResult
            });
          });
    },
    onUpdated(e) {
      const {note} = e.data;
      axios
          .put(`edit-note/${e.data.id}`, {
            note
          })
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla güncellendi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onInserted(e) {
      const {note} = e.data;

      axios
          .post('create-note', {
            note
          })
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla eklendi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onRemoved(e) {
      axios
          .post(`delete-note/${e.data.id}`, {})
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla silindi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    async refreshList() {
      this.dataLoading = true;
      await this.getLanguage();
      await this.getNoteData();
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';

.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>
